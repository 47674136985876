<template>
  <div class="about">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <div class="location hidden-xs-only">
        <div class="section wow animate__animated animate__fadeInLeft">
            <div class="tarbar">
                <span>您的位置：</span>
                <span @click="handleChange(1)">首页</span>
                <span>></span>
                <span class="newsCenter">关于我们</span>
            </div>
        </div>
    </div>
    <!-- pc -->
    <div class="aboutContent hidden-xs-only">
        <div class="companyProfile wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>企业简介</span>
                    </div>
                </div>
                <div class="content_top">
                    <div>上海南捷信息科技有限公司于2014年，由业内多位专家共同发起成立，专注于提供软件定义广域网整体解决方案，电信级网络通信解决方案以及多样化的信息技术和通信技术基础架构服务。通过南捷信息建立的战略生态系统，实现端到端的ICT专业服务，快速、高效、专业的交付体验和数字化解决方案。总部设在上海，在苏州、深圳、 北京、香港等地设有分公司和办事处。</div>
                </div>
                <div class="content_bottom">
                    <!-- <img src="../../assets/images/about/about.png" alt=""> -->
                    <Maps></Maps>
                </div>
            </div>
        </div>
        <div class="development wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>发展历程</span>
                    </div>
                </div>
            </div>
            <div class="development_bottom">
                <div class="section">
                    <swiper :options="swiperOptionThree" ref="mySwiperThree">
                       <swiper-slide
                          class="swiper-slide"
                          v-for="(item, index) in developmentList"
                          :key="index"
                          >
                          <div class="slide_item">
                             <!-- <img :src="item.imgSrc" /> -->
                             <div class="slide_item_content">
                                <div class="itemtitle">{{item.title}}</div>
                                <div class="itemtime">{{item.time}}</div>
                             </div>
                             <div class="swiper_circle"></div>
                          </div>
                       </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev">&lt;</div>
                    <div class="swiper-button-next" slot="button-next">&gt;</div>
                    </swiper>
                </div>
                <div class="swiper_timeLine"></div>
            </div>
        </div>
        <div class="maps wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>联系我们</span>
                    </div>
                </div>
                <div class="content">
                    <div class="left">
                        <div class="item" v-for="(Item,index) in positionList" :key="index">
                            <div class="itemName" @click="onClick(index)" :class="{ selected: active == index }">{{Item.name}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <baidu-map class="bm-view" :center="position1" :zoom="zoom">
                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                            <bm-geolocation
                                anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                                :showAddressBar="true"
                                :autoLocation="true"
                            ></bm-geolocation>
                            <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                            <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                            <bm-info-window :position="position1" show>
                                <p>地址：{{ItemMap.address}}</p>
                                <!-- <p>电话：{{ItemMap.phone}}</p>
                                <p>传真：{{ItemMap.chuan}}</p>
                                <p>邮箱：{{ItemMap.mail}}</p> -->
                            </bm-info-window>
                        </baidu-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- mobile -->
    <div class="mobileAbout hidden-sm-and-up">
        <div class="companyProfile wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>企业简介</span>
                    </div>
                </div>
                <div class="content_bottom">
                    <Maps></Maps>
                </div>
                <div class="content_top">
                    <div>上海南捷信息科技有限公司于2014年，由业内多位专家共同发起成立，专注于提供软件定义广域网整体解决方案，电信级网络通信解决方案以及多样化的信息技术和通信技术基础架构服务。通过南捷信息建立的战略生态系统，实现端到端的ICT专业服务，快速、高效、专业的交付体验和数字化解决方案。总部设在上海，在苏州、深圳、 北京、香港等地设有分公司和办事处。</div>
                </div>
            </div>
        </div>
        <div class="development wow animate__animated animate__fadeInDown">\
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>发展历程</span>
                    </div>
                </div>
            </div>
            <div class="development_bottom">
                <div class="section">
                    <swiper :options="swiperOption" ref="mySwiperThree">
                       <swiper-slide
                          class="swiper-slide"
                          v-for="(item, index) in developmentList"
                          :key="index"
                          >
                          <div class="slide_item">
                             <!-- <img :src="item.imgSrc" /> -->
                             <div class="slide_item_content">
                                <div class="itemtitle">{{item.title}}</div>
                                <div class="itemtime">{{item.time}}</div>
                             </div>
                             <div class="swiper_circle"></div>
                          </div>
                       </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev">&lt;</div>
                    <div class="swiper-button-next" slot="button-next">&gt;</div>
                    </swiper>
                </div>
                <div class="swiper_timeLine"></div>
            </div>
        </div>
        <div class="maps wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>联系我们</span>
                    </div>
                </div>
                <div class="content">
                    <div class="left">
                        <div class="item" v-for="(Item,index) in positionList" :key="index">
                            <div class="itemName" @click="onClick(index)" :class="{ selected: active == index }">{{Item.name}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <baidu-map class="bm-view" :center="position1" :zoom="zoom">
                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                            <bm-geolocation
                                anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                                :showAddressBar="true"
                                :autoLocation="true"
                            ></bm-geolocation>
                            <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                            <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                            <bm-info-window :position="position1" show>
                                <p>地址：{{ItemMap.address}}</p>
                                <!-- <p>电话：{{ItemMap.phone}}</p>
                                <p>传真：{{ItemMap.chuan}}</p>
                                <p>邮箱：{{ItemMap.mail}}</p> -->
                            </bm-info-window>
                        </baidu-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Maps from '../../components/maps.vue'
export default {
  components: { Maps },
  data() {
    return {
        active:0,
        zoom: 16,
        positionList: [
        {
          name:'上海',
          lng: 121.3909,
          lat: 31.181969,
          address: "上海市闵行区吴中路1189号德必易园",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        },
        {
          name:'苏州',
          lng: 120.677878,
          lat: 31.332135,
          address: "江苏省苏州市工业园区苏虹西路99号微格科创园",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        },
        {
          name:'香港',
          lng: 114.228315,
          lat: 22.318579,
          address: "香港九龙观塘道348号国际贸易中心6楼",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        },
        {
          name:'北京',
          lng: 116.423186,
          lat: 39.89877,
          address: "北京市东城区珠市口东大街2号丰泰中心",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        }
        ],
        ItemMap:{},
        position1: { lng: 121.3909, lat: 31.181969 },
        developmentList:[
            {
                time:'2014',
                title:'2014年公司成立'
            },
            {
                time:'2015',
                title:'2015年干网络建设开启，打造全国7x24小时售后服务体系'
            },
            // {
            //     time:'2016',
            //     title:'香港总部获得香港电信运营牌照'
            // },
            {
                time:'2017',
                title:'2017年获得跨区域增值电信业务许可'
            },
            {
                time:'2018',
                title:'2018年香港分公司成立并获得香港电信运营牌照兵正式成为中移动国际战略伙伴'
            },
            {
                time:'2019',
                title:'2019年成为全球多个T1类运营商亚太战略合作伙伴'
            },
        ],
        swiperOptionThree: {
            loop: false, //是否循环轮播
            autoplay: false, //可选选项，自动滑动
            slidesPerView: 5,
            spaceBetween: 15,
            //左右切换
            navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            },
        },
        swiperOption: {
            loop: false, //是否循环轮播
            autoplay: false, //可选选项，自动滑动
            slidesPerView: 3,
            spaceBetween: 15,
            //左右切换
            navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            },
        },
    };
  },
  mounted() {
    new this.$wow.WOW().init();
    this.getMap(0)
  },
  created() {
  },
  methods:{
    handleChange(num) {
      this.$store.commit("changeNum", num);
      this.$router.push('/index')
    },
    onClick(index) {
        this.active = index
        this.getMap(index)
    },
    getMap(indexs){
        this.position1 = {
          lng: this.positionList[indexs].lng,
          lat: this.positionList[indexs].lat
        };
        // this.address1 = this.positionList[indexs].address;
        this.ItemMap = this.positionList[indexs]
    },
  }
};
</script>
<style lang="scss">
.about {
    padding-top:92px ;
  .productTop {
    img {
      width: 100%;
      height: 482px;
    }
  }
  .location {
    padding: 50px 0;
    .section {
        font-size: 18px;
        .tarbar {
            cursor: pointer;
            .newsCenter {
                color: #197dbe;
            }
        }
    }
  }
  .newsContent {
    .section {
        .title {
            padding-bottom: 82px;
            text-align: center;
            display: flex;
            flex-direction: column;
            .title_one {
            span {
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            }
            .title_two {
            margin-top: 8px;
            span {
                font-size: 16px;
                display: inline-block;
                background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            }
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .Item {
                padding-top:60px;
                width: 600px;
                .ItemContent{
                    width: 512px;
                    img {
                        width: 512px;
                        height: 186px;
                        transition: all 0.3s linear;
                    }
                    .title{
                        font-size: 24px;
                        text-align: left;
                        padding: 36px 0;
                        overflow : hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }
                    .content{
                        font-size: 18px;
                        line-height: 26px;
                        overflow : hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                    }
                    .time{
                        font-size: 60px;
                        padding: 10px 0 30px 0;
                    }
                }
            }
            .Item:first-child {
                padding-top:0;
            }
            .Item:nth-child(2) {
                padding-top:0;
            }
            
            .Item:nth-child(2n) {
                padding-left:88px;
                border-bottom: 1px solid #ededed;
            }
            .Item:nth-child(2n-1) {
                border-right: 1px solid #ededed;
                border-bottom: 1px solid #ededed;
            }
            .Item:nth-child(5) {
                border-bottom: none;
            }
            .Item:nth-child(6) {
                border-bottom: none;
            }
            .Item:hover {
                .ItemContent {
                    img {
                        transform: scale(1.1);
                    }
                    .title,.content,.time {
                        // display: inline-block;
                        background-image: linear-gradient(
                        to right,
                        #25a9e0,
                        #187aba
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
  }
  .aboutContent {
    .companyProfile {
        .section {
            .title {
                text-align: center;
                padding-bottom: 50px;
                display: flex;
                flex-direction: column;
                .title_one {
                span {
                    font-size: 36px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
                .title_two {
                span {
                    font-size: 18px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
            }
            .content_top {
                div {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            .content_bottom {
                display: flex;
                justify-content: center;
                padding: 10px 0 30px 0;
                img {
                  height: 605px;
                }
            }
        }
    }
    .development {
        height: 504px;
        background: url('../../assets/images/index/development.png') no-repeat center center;
        background-size: cover;
        .section {
            .title {
                text-align: center;
                padding: 60px 0 50px 0;
                display: flex;
                flex-direction: column;
                .title_one {
                    span {
                        display: inline-block;
                        font-size: 36px;
                        color: #fff;
                    }
                }
                .title_two {
                    span {
                        display: inline-block;
                        font-size: 18px;
                        color: #fff;
                    }
                }
            }
        }
        .development_bottom {
            position: relative;
            color: #fff;
            .section {
                .swiper-container {
                    // height: 280px;
                    // border-bottom:2px solid red ;
                    // position: relative;
                    .swiper-wrapper {
                        // position: absolute;
                        // height: 100px;
                        margin-top: 10px;
                        .swiper-slide  {
                            .slide_item {
                                img {
                                    width: 100%;
                                }
                                .slide_item_content {
                                    .itemtitle {
                                        height: 50px;
                                        font-size: 17px;
                                    }
                                    .itemtime{
                                        font-size: 17px;
                                    }
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: 0;
                                    content: '▲';
                                }
                                
                            }
                        }
                        .swiper-slide:nth-child(2n)  {
                            margin-top: 105px;
                            .slide_item {
                                .slide_item_content {
                                    display: flex;
                                    flex-direction: column-reverse;
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: -95px;
                                    content: '▼';
                                }
                                
                            }
                        }
                        
                    }
                }
                .swiper-button-prev {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    left: 0;
                    top: 108px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
                .swiper-button-next {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    right: 0;
                    top: 108px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
            }
            .swiper_timeLine {
                position: absolute;
                top: 97px;
                left: 0;
                margin: auto;
                width: 100%;
                // background-color: #5583f9;
                border-bottom: 1px solid #ffffff;
            }
        }
    }
    .maps {
        height: 370px;
        margin-bottom: 260px;
        .section {
            .title {
                text-align: center;
                padding: 82px 0 40px 0;
                display: flex;
                flex-direction: column;
                .title_one {
                span {
                    font-size: 36px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
                .title_two {
                span {
                    font-size: 18px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
            }
            .content {
                display: flex;
                .left {
                    width: 7%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    padding: 0 20px 0 10px;
                    .item{
                        font-size: 20px;
                        .itemName {
                            padding: 0 0 10px 4px;
                            cursor: pointer;
                        }
                        .selected {
                            border-bottom: 3px solid #197dbe;
                            color: #197dbe;
                        }
                    }
                }
                .right {
                    width: 93%;
                    height: 370px;
                    .bm-view {
                       height: 370px;
                    }
                }
            }
        }
    }
  }
}
@media (max-width: 768px) {
  .about {
    padding-top:0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .mobileAbout {
        // padding: 0 .4rem;
        padding-bottom: .4rem;
        .companyProfile {
            .section {
                .title{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem 0;
                    .title_one{
                        span {
                            font-size: .45rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .title_two {
                        span {
                            font-size: .25rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
                .content_bottom {
                    width: 100%;
                    img {
                        width: 100%;
                        height: 6rem;
                    }
                }
                .content_top {
                    div {
                        line-height: .45rem;
                    }
                }
            }
        }
        .development {
            margin:.3rem 0;
            // background-color: #14689d;
            background: url('../../assets/images/index/mobile_dev.png') no-repeat center center;
            background-size: cover;
            width: 100%;
            height: 6rem;
            .section {
                .title{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem 0;
                    .title_one{
                        span {
                            font-size: .45rem;
                            color: #fff;
                        }
                    }
                }
            }
            .development_bottom {
            position: relative;
            color: #fff;
            .section {
                .swiper-container {
                    // height: 280px;
                    // border-bottom:2px solid red ;
                    // position: relative;
                    .swiper-wrapper {
                        // position: absolute;
                        // height: 100px;
                        margin-top: 20px;
                        .swiper-slide  {
                            .slide_item {
                                img {
                                    width: 100%;
                                }
                                .slide_item_content {
                                    .itemtitle {
                                        height: 55px;
                                        font-size: .2rem;
                                    }
                                    .itemtime{
                                        font-size: .25rem;
                                    }
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: 0;
                                    content: '▲';
                                }
                                
                            }
                        }
                        .swiper-slide:nth-child(2n)  {
                            margin-top: 105px;
                            .slide_item {
                                .slide_item_content {
                                    display: flex;
                                    flex-direction: column-reverse;
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: -95px;
                                    content: '▼';
                                }
                                
                            }
                        }
                        
                    }
                }
                .swiper-button-prev {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    left: 0;
                    top: 119px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
                .swiper-button-next {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    right: 0;
                    top: 119px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
            }
            .swiper_timeLine {
                position: absolute;
                top: 106px;
                left: 0;
                margin: auto;
                width: 100%;
                // background-color: #5583f9;
                border-bottom: 1px solid #ffffff;
            }
        }
        }
        .maps {
            .section {
                .title{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem 0;
                    .title_one{
                        span {
                            font-size: .45rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .title_two {
                        span {
                            font-size: .25rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
                .content{
                    .left {
                        display: flex;
                        justify-content: space-between;
                        .item {
                            font-size: .4rem;
                            .itemName {
                                padding: 0 0 .15rem .05rem;

                            }
                            .selected {
                                border-bottom: 2px solid #197dbe;
                                color: #197dbe;
                            }
                        }
                    }
                    .right {
                        margin-top: .15rem;
                        width: 100;
                        height: 3.5rem;
                        .bm-view {
                        height: 3.5rem;
                        }
                    }
                }
            }
        }
    }
  }
}
</style>